<template>
  <!-- begin::Page loader -->
  <b-tr v-if="newStyle === 3" :style="`opacity: 1`">
    <b-td v-for="(field, findex) in fields" v-bind:key="`${ field }-${ findex }`">
      <Loader :newStyle="1"></Loader>
    </b-td>
  </b-tr>
  <b-table-simple responsive v-else-if="newStyle === 2">
    <b-thead v-if="hideHeader !== true">
      <b-tr>
        <b-th v-for="field in fields" v-bind:key="field">{{ field }}</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr v-for="(item, index) in loaderCounter" v-bind:key="`${ item }-${ index }`"
        :style="`opacity: ${ 1 - (1 / loaderCounter) * item }`">
        <b-td v-for="(field, findex) in fields" v-bind:key="`${ item }-${ index }-${ field }-${ findex }`">
          <Loader :newStyle="1"></Loader>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
  <vue-content-loading :width="width || 200" :height="20" :style="`width: ${ width || 200 }px; height: 20px`"
    v-else-if="newStyle === 1">
    <rect x="5" y="5" rx="5" ry="5" :width="(width || 200) - 10" height="10" />
  </vue-content-loading>
  <div v-else class="kt-page-loader kt-page-loader--logo" :style="
    'padding: 20px; background: ' +
    (background || 'transparent') +
    ' !important;' +
    (inContent === true
      ? 'display: block; position: relative; text-align: center; margin: 0 auto; z-index: 0;'
      : '')
  ">
    <b-spinner style="
        width: 5rem;
        height: 5rem;
        text-align: center;
        border-width: 5px;
        color: var(--secondary);
      "></b-spinner>
    <div style="height: 40px;" v-if="noText !== true"></div>
    <h3 class="text-center" style="color: var(--secondary)" v-if="noText !== true">
      {{ text || "Loading Content" }}{{ ".".repeat(dotCount) }}
    </h3>

    <!-- <img alt="Logo" src="/assets/media/_bcorp/niiinja.gif" :style="'max-width: 300px;' + (inContent === true ? 'margin: 0 auto;' : '')" />
    <h4 style="text-align: center;">Our team of ninjas are busy setting up the stage for you. Please wait a bit.</h4>-->
    <!-- <img alt="Logo" :src="logo" /> -->
    <!-- <div
      class="kt-spinner"
      v-bind:class="spinnerClass || 'kt-spinner--brand'"
    ></div>-->
  </div>
  <!-- end::Page Loader -->
</template>

<script>
import VueContentLoading from "vue-content-loading";

export default {
  components: {
    VueContentLoading
  },
  name: "Loader",
  props: {
    background: String,
    logo: String,
    text: String,
    spinnerClass: String,
    fields: Array,
    inContent: Boolean,
    hideHeader: Boolean,
    noText: Boolean,
    newStyle: Number,
    tableCount: Number,
    width: Number
  },
  data() {
    return {
      dotCount: 0,
      timer: null,
      timer2: null,
      loaderCounter: 1
    };
  },
  beforeDestroy() {
    //clearInterval(this.timer);
    clearInterval(this.$data.timer);
    clearInterval(this.$data.timer2);
    this.$data.timer = null;
    this.$data.timer2 = null;
  },
  computed: {
    tableCounter() {
      return this.tableCount || 10;
    }
  },
  mounted() {
    let self = this;
    if (this.newStyle === 2) {
      self.$data.timer = setInterval(() => {
        self.$data.loaderCounter++;
        if (self.loaderCounter >= self.tableCounter) {
          clearInterval(self.$data.timer);
          self.$data.timer = null;
        }
      }, 250);
    }
    if (this.noText !== true)
      self.$data.timer2 = setInterval(() => {
        self.$data.dotCount++;
        if (self.$data.dotCount > 5) self.$data.dotCount = 0;
      }, 350);
  }
};
</script>
